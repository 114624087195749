import React, { useState } from 'react';
import styled from 'styled-components';
import { TrophyOutlined, GiftOutlined, CalendarOutlined, TeamOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { TabBar } from '../components/TabBar';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  $bgImage?: string;
}

const PageContainer = styled.div`
  padding: 16px;
  padding-bottom: 60px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const HeaderCard = styled.div`
  background: linear-gradient(135deg, rgba(255, 59, 48, 0.95) 0%, rgba(200, 35, 28, 0.95) 100%);
  border-radius: 16px;
  padding: 32px 24px;
  color: white;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(255, 59, 48, 0.2);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 70% 30%, rgba(255, 255, 255, 0.1) 0%, transparent 60%),
      radial-gradient(circle at 30% 70%, rgba(255, 255, 255, 0.08) 0%, transparent 50%);
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 12px 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const Subtitle = styled.p`
  font-size: 16px;
  margin: 0;
  opacity: 0.9;
  position: relative;
  max-width: 80%;
  line-height: 1.5;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 24px 0;
`;

const FeatureCard = styled.div<CardProps>`
  background: white;
  border-radius: 16px;
  padding: 24px 20px;
  text-align: center;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.$bgImage ? `url(${props.$bgImage}) right bottom/120px no-repeat` : 'none'};
    opacity: 0.05;
    transition: all 0.3s ease;
  }

  .anticon {
    font-size: 28px;
    color: #1890ff;
    margin-bottom: 12px;
    transition: all 0.3s ease;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(24, 144, 255, 0.15);

    &::before {
      opacity: 0.1;
      transform: scale(1.1);
    }

    .anticon {
      transform: scale(1.1);
      color: #096dd9;
    }
  }
`;

const FeatureTitle = styled.h3`
  font-size: 16px;
  margin: 0 0 8px 0;
  color: #333;
  white-space: nowrap;
`;

const FeatureDesc = styled.p`
  font-size: 13px;
  margin: 0;
  color: #666;
  line-height: 1.5;
`;

const NewsSection = styled.div`
  margin-top: 32px;
`;

const NewsTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    font-size: 20px;
    margin: 0;
    color: #333;
  }

  span {
    margin-left: 12px;
    font-size: 14px;
    color: #999;
  }
`;

const NewsCard = styled.div`
  background: white;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);

  &:hover {
    transform: translateX(4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-color: rgba(24, 144, 255, 0.3);
  }
`;

const NewsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const NewsContent = styled.h3`
  font-size: 16px;
  margin: 0;
  color: #333;
  display: flex;
  align-items: center;

  img {
    margin-right: 12px;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const NewsDate = styled.span`
  font-size: 13px;
  color: #999;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background: #1890ff;
    border-radius: 50%;
    margin-right: 8px;
  }
`;

const NewsDesc = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  line-height: 1.6;
`;

const BackButton = styled.button`
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 1001;
  display: flex;
  align-items: center;
  gap: 6px;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  &:active {
    transform: translateX(-50%) scale(0.98);
  }
`;

const PosterModal = styled(Modal)`
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
    padding: 0;
    position: relative;
  }

  .ant-modal-body {
    padding: 0;
    
    img {
      width: 100%;
      height: auto;
      border-radius: 12px;
    }
  }

  .ant-modal-close {
    display: none;
  }
`;

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [showPoster, setShowPoster] = useState(false);
  const [currentPoster, setCurrentPoster] = useState<string>('/images/poster.jpg');

  const handlePosterClick = (posterPath: string) => {
    setCurrentPoster(posterPath);
    setShowPoster(true);
  };

  const handleFormRedirect = () => {
    window.open('http://bd.zhubaotong.com/web/formview/6753af57fc918f22d85e3e20?_ed=1', '_blank');
  };

  const handleRankingClick = () => {
    window.open('https://www.jsform.com/web/formview/6753b1bcfc918f22d85e4ac7?serviceName=general&code=063b8Gkl2nWqFe4t0Jll2y352i2b8Gk2&state=bdZzhubaotongZcom', '_blank');
  };

  return (
    <PageContainer>
      <HeaderCard>
        <Title>年终巅峰对决</Title>
        <Subtitle>
          参与年终挑战赛，赢取丰厚奖励！让我们一起创造属于自己的精彩时刻。
        </Subtitle>
      </HeaderCard>

      <FeaturesGrid>
        <FeatureCard 
          $bgImage="/images/trophy-bg.png"
          onClick={handleRankingClick}
        >
          <TrophyOutlined />
          <FeatureTitle>赛事报名</FeatureTitle>
          <FeatureDesc>及时进入比赛赛事，掌握竞争动态</FeatureDesc>
        </FeatureCard>
        <FeatureCard $bgImage="/images/gift-icon.png">
          <GiftOutlined />
          <FeatureTitle>丰富奖励</FeatureTitle>
          <FeatureDesc>多重好礼等你来拿，惊喜不断</FeatureDesc>
        </FeatureCard>
        <FeatureCard 
          $bgImage="/images/medal.png"
          onClick={handleFormRedirect}
        >
          <CalendarOutlined />
          <FeatureTitle>业绩填报</FeatureTitle>
          <FeatureDesc>把握机会冲刺排名，创造佳绩</FeatureDesc>
        </FeatureCard>
      </FeaturesGrid>

      <NewsSection>
        <NewsTitle>
          <h2>最新动态</h2>
          <span>实时更新活动信息</span>
        </NewsTitle>
        <NewsCard onClick={() => handlePosterClick('/images/poster.jpg')}>
          <NewsHeader>
            <NewsContent>
              <img 
                src="/images/gift-icon.png" 
                alt="奖励"
                width="24"
                height="24"
              />
              年终挑战赛正式开启
              <span style={{ 
                marginLeft: '8px', 
                fontSize: '12px', 
                color: '#1890ff' 
              }}>
                点击查看详情
              </span>
            </NewsContent>
            <NewsDate>2024-12-7</NewsDate>
          </NewsHeader>
          <NewsDesc>
            2024年度最后一场挑战赛开始啦！参与比赛，展现自我，赢取丰厚奖励！
            让我们一起创造属于自己的精彩时刻。
          </NewsDesc>
        </NewsCard>

        <NewsCard onClick={() => handlePosterClick('/images/poster1.png')}>
          <NewsHeader>
            <NewsContent>
              <img 
                src="/images/gift-icon.png" 
                alt="奖励"
                width="24"
                height="24"
              />
              2024.12.8-2024.12.18十日榜
              <span style={{ 
                marginLeft: '8px', 
                fontSize: '12px', 
                color: '#1890ff' 
              }}>
                点击查看详情
              </span>
            </NewsContent>
            <NewsDate>2024-12-19</NewsDate>
          </NewsHeader>
          <NewsDesc>
            第一阶段排行榜火热出炉！看看谁是本次十日榜的销售之星，一起为年终冲刺加油！
          </NewsDesc>
        </NewsCard>

        <NewsCard onClick={() => handlePosterClick('/images/poster2.png')}>
          <NewsHeader>
            <NewsContent>
              <img 
                src="/images/gift-icon.png" 
                alt="奖励"
                width="24"
                height="24"
              />
              2024.12.19-2024.12.28十日榜
              <span style={{ 
                marginLeft: '8px', 
                fontSize: '12px', 
                color: '#1890ff' 
              }}>
                点击查看详情
              </span>
            </NewsContent>
            <NewsDate>2024-12-29</NewsDate>
          </NewsHeader>
          <NewsDesc>
            年终挑战赛进入中期冲刺阶段，让我们一起见证王者的诞生！
          </NewsDesc>
        </NewsCard>

        <NewsCard onClick={() => handlePosterClick('/images/poster3.png')}>
          <NewsHeader>
            <NewsContent>
              <img 
                src="/images/gift-icon.png" 
                alt="奖励"
                width="24"
                height="24"
              />
              2024.12.29-2025.1.8十日榜
              <span style={{ 
                marginLeft: '8px', 
                fontSize: '12px', 
                color: '#1890ff' 
              }}>
                点击查看详情
              </span>
            </NewsContent>
            <NewsDate>2025-1-9</NewsDate>
          </NewsHeader>
          <NewsDesc>
            人员管理心得体会，年终挑战赛进入冲刺，让我们一起见证最终王者的诞生！
          </NewsDesc>
        </NewsCard>
      </NewsSection>

      <PosterModal
        open={showPoster}
        onCancel={() => setShowPoster(false)}
        footer={null}
        width="100%"
        style={{ maxWidth: '500px', margin: '0 auto' }}
        centered
        destroyOnClose
        closable={false}
      >
        <img 
          src={currentPoster}
          alt="活动海报"
          style={{ width: '100%', height: 'auto' }}
        />
        {showPoster && (
          <BackButton onClick={() => setShowPoster(false)}>
            返回首页
          </BackButton>
        )}
      </PosterModal>

      <TabBar />
    </PageContainer>
  );
}; 